import { useEffect, useRef, useState } from "react";
import useCourse from "../../hooks/useCourse";
import { useDispatch } from "react-redux";
import {
  fetchCourseData,
  removeCourseData,
  saveCourseData,
  updateCourseData,
} from "../../redux/thunks/courseThunk";
import { fetchSubscribersByCourse } from "../../redux/thunks/subscriberThunk";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Joi from "joi";
import { FaUserFriends } from "react-icons/fa";
import {
  fetchImageData,
  removeImageData,
  saveImageData,
} from "../../redux/thunks/imageThunk";
import Loader from "../common/loader";
import {
  Dialog,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";

const PanelCourse = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Form for Cursos
  const [formCourseData, setFormCourseData] = useState({
    title: "",
    introduction: "",
    objective: "",
    addressed_to: "",
    language: "Español",
    content: "",
    duration: "",
    modality: "Presencial",
    frequency: "",
    start_date: new Date(),
    benefits: "",
    investment: "",
    timestamp: new Date(),
    image: null,
    imageKey: null,
    fileImage: null,
    state: true,
    user: localStorage.getItem("email"),
  });

  const inputFileRef = useRef(null);

  const { loading, courseData, error } = useCourse();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = courseData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    document.title = "Panel - Cursos";
    Object.keys(courseData).length === 0 && dispatch(fetchCourseData());
  }, []);

  const courseItemSchema = Joi.object({
    title: Joi.string().required(),
    introduction: Joi.string().required(),
    objective: Joi.string().required(),
    start_date: Joi.date().required(),
    addressed_to: Joi.string().required(),
    language: Joi.string().required(),
    content: Joi.string().required(),
    duration: Joi.string().required(),
    hours: Joi.number().required(),
    modality: Joi.string().required(),
    frequency: Joi.string().required(),
    benefits: Joi.string().required(),
    investment: Joi.string().required(),
    state: Joi.required(),
    image: Joi.optional(),
    imageKey: Joi.optional(),
    fileImage: Joi.optional(),
    urlImage: Joi.optional(),
    timestamp: Joi.optional(),
    user: Joi.optional(),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formCourseData);
    try {
      await courseItemSchema.validateAsync(formCourseData, {
        abortEarly: false,
      });
      if (selectedRow === null) {
        // Add course
        if (formCourseData.fileImage != null) {
          const formData = new FormData();
          formData.append("image", formCourseData.fileImage);

          const imgResponse = await dispatch(saveImageData(formData));
          if (imgResponse instanceof Error || imgResponse.error) {
            throw new Error("La imagen no pudo subirse a la nube.");
          }
          formCourseData.image = imgResponse.payload.content;
          const fetchImgresponse = await dispatch(
            fetchImageData(formCourseData.image)
          );
          if (fetchImgresponse instanceof Error || fetchImgresponse.error) {
            throw new Error("La imagen no pudo cargarse.");
          }
          formCourseData.urlImage = fetchImgresponse.payload.content;
        }
        await dispatch(saveCourseData(formCourseData));
      } else {
        // Update course
        formCourseData.id = selectedRow;

        if (formCourseData.fileImage !== null) {
          const removeImgResponse = await dispatch(
            removeImageData(formCourseData.imageKey)
          );
          if (removeImgResponse instanceof Error || removeImgResponse.error) {
            throw new Error("La imagen no pudo eliminarse de la nube.");
          }

          const formData = new FormData();
          formData.append("image", formCourseData.fileImage);

          const saveImgResponse = await dispatch(saveImageData(formData));
          if (saveImgResponse instanceof Error || saveImgResponse.error) {
            throw new Error("La imagen no pudo eliminarse de la nube.");
          }
          formCourseData.image = saveImgResponse.payload.content;

          const fetchImgresponse = await dispatch(
            fetchImageData(formCourseData.image)
          );
          if (fetchImgresponse instanceof Error || fetchImgresponse.error) {
            throw new Error("La imagen no pudo subirse a la nube.");
          }
          formCourseData.urlImage = fetchImgresponse.payload.content;
        } else {
          formCourseData.image = null;
          formCourseData.urlImage = null;
        }
        await dispatch(updateCourseData(formCourseData));
      }

      cleanFields();
      await Swal.fire({
        icon: "success",
        title: "¡Datos Guardados!",
        text: "Los datos se han guardado exitosamente.",
      });
    } catch (error) {
      const validationErrors = error?.details?.map((detail) => detail.message);
      let message = error.message;
      if (validationErrors) {
        message = "Algunos campos son obligatorios.";
      }
      await Swal.fire({
        icon: "error",
        title: "Error al Guardar Datos",
        text: message,
      });
    }
    dispatch(fetchCourseData());
  };

  const handleDelete = async (id) => {
    try {
      // Show confirmation dialog before deleting
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Proceed with deletion if user confirms
      if (result.isConfirmed) {
        await dispatch(removeCourseData(id));
        if (formCourseData.imageKey)
          await dispatch(removeImageData(formCourseData.imageKey));

        cleanFields();
        await Swal.fire({
          icon: "success",
          title: "¡Datos Eliminados!",
          text: "Los datos se han eliminado exitosamente.",
        });
      }
    } catch (error) {
      // Handle errors
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error al eliminar el curso.",
      });
    }
    dispatch(fetchCourseData());
  };

  const handleView = (id) => {
    handleOpenDialog(id);
  };

  const handleUpdate = async (row) => {
    setSelectedRow(row.id);
    setFormCourseData({
      title: row.title,
      introduction: row.introduction,
      objective: row.objective,
      addressed_to: row.addressed_to,
      language: row.language,
      content: row.content,
      duration: row.duration,
      modality: row.modality,
      state: row.state,
      hours: row.hours,
      frequency: row.frequency,
      start_date: row.start_date,
      benefits: row.benefits,
      investment: row.investment,
      timestamp: row.timestamp,
      image: row.urlImage,
      imageKey: row.image,
      fileImage: null,
    });
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    cleanFields();
  };

  const cleanFields = () => {
    setSelectedRow(null);
    setFormCourseData({
      title: "",
      introduction: "",
      objective: "",
      addressed_to: "",
      language: "Español",
      content: "",
      duration: "",
      state: true,
      hours: 0,
      modality: "Presencial",
      frequency: "",
      start_date: new Date(),
      benefits: "",
      investment: "",
      timestamp: new Date(),
      image: null,
      imageKey: null,
      fileImage: null,
      user: localStorage.getItem("email"),
    });
    inputFileRef.current.value = null;
  };

  const clearImage = () => {
    setFormCourseData((prevData) => ({
      ...prevData,
      fileImage: null,
      image: null,
      imageKey: null,
    }));
    // Clear the input file value to allow selecting the same file again
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };

  // Function to handle opening the dialog
  const handleOpenDialog = async (courseId) => {
    // Fetch subscribers for the selected course
    const data = await dispatch(fetchSubscribersByCourse(courseId));
    setSubscribers(data.payload.data);
    setDialogOpen(true);
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  let filteredCourseData = [];

  if (Array.isArray(courseData)) {
    filteredCourseData = courseData.filter((course) =>
      course.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <div>
      {loading && <Loader />}

      <div className="px-3 pt-5 container">
        <p className="title-text text-center pt-5">Cursos</p>

        <div className="row">
          <div className="col-12 col-xl-12 px-4 py-4">
            <form className="paragraph-text">
              <div className="row d-flex d-flex--center pb-4 gap-xs">
                <div className="form-floating col-12 col-lg-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Título"
                    value={formCourseData.title}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        title: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="title">Título</label>
                </div>

                <div className="col-12 col-lg-3 mb-3">
                  <input
                    ref={inputFileRef}
                    type="file"
                    className="form-control"
                    id="image"
                    accept="image/*"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      setFormCourseData((prevData) => ({
                        ...prevData,
                        fileImage: selectedFile,
                        image: selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : null,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 col-lg-1 mb-3 d-flex align-items-center justify-content-center">
                  {formCourseData.image && (
                    <button type="button" className="btn" onClick={clearImage}>
                      <AiFillDelete className="row-icon" />
                    </button>
                  )}
                </div>

                <div className="col-12 col-lg-2 img-container">
                  {formCourseData.image && (
                    <img
                      className="xs-img img-preview"
                      src={formCourseData.image}
                      alt="Course preview"
                    />
                  )}
                </div>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control textarea"
                  id="introduction"
                  placeholder="Introducción"
                  value={formCourseData.introduction}
                  onChange={(e) =>
                    setFormCourseData({
                      ...formCourseData,
                      introduction: e.target.value,
                    })
                  }
                  style={{
                    height: "10rem",
                  }}
                />
                <label htmlFor="introduction">Introducción</label>
              </div>
              <div className="d-flex d-flex--center d-flex--direction-row gap-xs my-3 mx-2">
                <div className="form-floating col-4 px-2">
                  <select
                    id="modality"
                    className="form-control"
                    value={formCourseData.modality}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        modality: e.target.value,
                      })
                    }
                  >
                    <option value="Presencial">Presencial</option>
                    <option value="Virtual">Virtual</option>
                  </select>
                  <label>Modalidad</label>
                </div>
                <div className="form-floating col-4 px-2">
                  <select
                    id="language"
                    className="form-control"
                    value={formCourseData.language}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        language: e.target.value,
                      })
                    }
                  >
                    <option value="Presencial">Español</option>
                    <option value="Virtual">Inglés</option>
                  </select>
                  <label>Idioma</label>
                </div>
                <div className="form-floating col-4 px-2">
                  <select
                    id="state"
                    className="form-control"
                    value={formCourseData.state}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        state: e.target.value,
                      })
                    }
                  >
                    <option value="true">Activo</option>
                    <option value="false">Inactivo</option>
                  </select>
                  <label>Estado</label>
                </div>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control textarea"
                  id="content"
                  placeholder="Contenido"
                  value={formCourseData.content}
                  onChange={(e) =>
                    setFormCourseData({
                      ...formCourseData,
                      content: e.target.value,
                    })
                  }
                  style={{
                    height: "10rem",
                  }}
                />
                <label htmlFor="content">Contenido</label>
              </div>

              <div className="d-flex d-flex--center d-flex--direction-row gap-xs my-3 px-4">
                <div className="form-floating mb-3 col-md-3 col-12">
                  <input
                    type="date"
                    className="form-control"
                    id="start_date"
                    placeholder="Inicio de Clases"
                    value={formCourseData.start_date}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        start_date: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="start_date">Inicio de Clases</label>
                </div>
                <div className="form-floating mb-3 col-md-3 col-12">
                  <input
                    type="text"
                    className="form-control"
                    id="frequency"
                    placeholder="Frecuencia"
                    value={formCourseData.frequency}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        frequency: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="frequency">Frecuencia</label>
                </div>
                <div className="form-floating mb-3 col-md-3 col-12">
                  <input
                    type="text"
                    className="form-control"
                    id="duration"
                    placeholder="Duración"
                    value={formCourseData.duration}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        duration: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="duration">Duración</label>
                </div>
                <div className="form-floating mb-3 col-md-3 col-12">
                  <input
                    type="number"
                    className="form-control"
                    id="hours"
                    placeholder="Horas"
                    value={formCourseData.hours}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        hours: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="hours">Horas</label>
                </div>
              </div>

              <div className="d-flex d-flex--center d-flex--direction-row gap-xs my-3 px-2">
                <div className="form-floating mb-3 col-md-6 col-12">
                  <textarea
                    className="form-control textarea"
                    id="objective"
                    placeholder="Objetivo"
                    value={formCourseData.objective}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        objective: e.target.value,
                      })
                    }
                    style={{
                      height: "7rem",
                    }}
                  />
                  <label htmlFor="objective">Objetivo</label>
                </div>
                <div className="form-floating mb-3 col-md-6 col-12">
                  <textarea
                    className="form-control textarea"
                    id="addressed_to"
                    placeholder="Dirigido a"
                    value={formCourseData.addressed_to}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        addressed_to: e.target.value,
                      })
                    }
                    style={{
                      height: "7rem",
                    }}
                  />
                  <label htmlFor="addressed_to">Dirigido a</label>
                </div>
              </div>
              <div className="d-flex d-flex--center d-flex--direction-row gap-xs my-3 px-2">
                <div className="form-floating mb-3 col-md-6 col-12">
                  <textarea
                    className="form-control textarea"
                    id="benefits"
                    placeholder="Beneficios"
                    value={formCourseData.benefits}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        benefits: e.target.value,
                      })
                    }
                    style={{
                      height: "7rem",
                    }}
                  />
                  <label htmlFor="benefits">Beneficios</label>
                </div>
                <div className="form-floating mb-3 col-md-6 col-12">
                  <textarea
                    className="form-control textarea"
                    id="investment"
                    placeholder="Inversión"
                    value={formCourseData.investment}
                    onChange={(e) =>
                      setFormCourseData({
                        ...formCourseData,
                        investment: e.target.value,
                      })
                    }
                    style={{
                      height: "7rem",
                    }}
                  />
                  <label htmlFor="investment">Inversión</label>
                </div>
              </div>

              <div className="d-flex d-flex--center d-flex--direction-row gap-md">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  aria-label="Guardar Curso"
                >
                  Guardar
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleCancel}
                  aria-label="Cancelar"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div className="row px-4">
            <div className="col-md-8 my-3">
              <ul className="pagination m-auto">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    aria-label="Pagina anterior"
                  >
                    <FaArrowAltCircleLeft />
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      aria-label="Pagina actual"
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    aria-label="Pagina siguiente"
                  >
                    <FaArrowAltCircleRight />
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-4 form-floating my-3">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por título"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              <label htmlFor="description">Buscar ...</label>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12 px-4 py-4 panel-container--sm">
            {filteredCourseData && courseData.length > 0 ? (
              <div>
                <table className="table table-bordered paragraph-text">
                  <thead className="table-header ">
                    <tr>
                      <th scope="col">Titulo</th>
                      <th scope="col">Modalidad</th>
                      <th scope="col">Inicio de clases</th>
                      <th scope="col">Duración</th>
                      <th scope="col">Opciones</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {filteredCourseData
                      .slice(startIndex, endIndex)
                      .map((item) => (
                        <tr
                          key={item.id}
                          className={
                            selectedRow === item.id ? "selected-row" : ""
                          }
                        >
                          <td>{item.title}</td>
                          <td>{item.modality}</td>
                          <td>{item.start_date}</td>
                          <td>{item.duration}</td>
                          <td>
                            <div className="d-flex d-flex--center d-flex--direction-row ">
                              <button
                                className="btn "
                                onClick={() => handleUpdate(item)}
                                aria-label="Editar Curso"
                              >
                                <AiFillEdit
                                  className={
                                    selectedRow === item.id
                                      ? "row-icon"
                                      : "secondary-small-icon"
                                  }
                                />
                              </button>
                              <button
                                className="btn "
                                aria-label="Eliminar Curso"
                                onClick={() => handleDelete(item.id)}
                              >
                                <AiFillDelete
                                  className={
                                    selectedRow === item.id
                                      ? "row-icon"
                                      : "secondary-small-icon"
                                  }
                                />
                              </button>
                              <button
                                className="btn "
                                aria-label="Ver Suscriptores"
                                onClick={() => handleView(item.id)}
                              >
                                <FaUserFriends
                                  className={
                                    selectedRow === item.id
                                      ? "row-icon"
                                      : "secondary-small-icon"
                                  }
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="subtitle-text">No hay cursos disponibles.</p>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md" // Adjust the width here
        fullWidth
      >
        <DialogContent>
          <p className="subtitle-text m-4">Suscriptores del Curso</p>
          <div style={{ overflowX: "auto" }}>
            <table className="table-auto w-full">
              <thead>
                <tr className="paragraph-text">
                  <th className="px-4 py-2 border">Nombre</th>
                  <th className="px-4 py-2 border">Email</th>
                  <th className="px-4 py-2 border">Teléfono</th>
                  <th className="px-4 py-2 border">Fecha de Suscripción</th>
                </tr>
              </thead>
              <tbody className="paragraph-text">
                {subscribers.length > 0 ? (
                  subscribers.map((subscriber) => (
                    <tr key={subscriber.id}>
                      <td className="px-4 py-2 border">{subscriber.name}</td>
                      <td className="px-4 py-2 border">{subscriber.email}</td>
                      <td className="px-4 py-2 border">{subscriber.phone}</td>
                      <td className="px-4 py-2 border">
                        {dayjs(subscriber.subscription_date)
                          .locale("es")
                          .format("D [de] MMMM [del] YYYY")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="px-4 py-2 border text-center">
                      No hay suscriptores para este curso
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseDialog} className="btn btn-primary m-3">
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PanelCourse;
