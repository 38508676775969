import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceNoAuth } from "../../config/axiosInstance";

export const fetchSubscriberData = createAsyncThunk(
  "subscriber/fetchSubscriberData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get("subscriber/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveSubscriberData = createAsyncThunk(
  "subscriber/saveSubscriberData",
  async (subscribers, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.post(
        "subscriber/",
        subscribers
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeSubscriberData = createAsyncThunk(
  "subscriber/removeSubscriberData",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`subscriber/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSubscriberData = createAsyncThunk(
  "subscriber/updateSubscriberData",
  async (subscriber, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `subscriber/${subscriber.id}`,
        subscriber
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubscriberDataById = createAsyncThunk(
  "subscriber/fetchSubscriberDataById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstanceNoAuth.get(`subscriber/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubscribersByCourse = createAsyncThunk(
  "subscriber/fetchSubscribersByCourse",
  async (courseId, { rejectWithValue }) => {
    try {
      // Convert searchParams object to query string
      const { data } = await axiosInstanceNoAuth.get(
        `subscriber/?course=${courseId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
